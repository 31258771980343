import fetcher from '@api/fetchers/simpleFetcher';
import { getHost, HOST_TYPE } from '@utils/api-helper/host';

const CMSHost = getHost(HOST_TYPE.CMS);

const fetchPageMetaInfo = (pageType, itemId, preview = false) =>
  fetcher(
    `${CMSHost}api/meta-data/${pageType}/${itemId}${
      preview ? '?_publicationState=preview' : ''
    }`
  );

export default fetchPageMetaInfo;
