import Router from 'next/router';

const getQueryString = (query = {}) => {
  // Either pass a query object from Next.js or the function uses the Next.js Router to get the query.
  const queryObjectToProcess =
    query && typeof query === 'object' ? query : Router.query;
  let queryString = '?';
  if (Object.keys(queryObjectToProcess).length) {
    const queryParams = Object.keys(queryObjectToProcess);
    for (let i = 0; i < queryParams.length; i += 1) {
      queryString += i > 0 ? '&' : '';
      queryString += `${queryParams[i]}=${
        queryObjectToProcess[queryParams[i]]
      }`;
    }
  }

  return queryString.length > 1 ? queryString : '';
};

export default getQueryString;
