import { getDefaultHost } from '@utils/api-helper/host';
import { PLATFORM } from '@constants/index';
import { getStargateJwtToken } from '@selectors/auth';
import deviceHelper from '@utils/deviceHelper';
import getTokens from './getTokens';

const defaultHost = getDefaultHost();

const getHeaders = ({
  url,
  getState,
  headersOptions: {
    useAuthToken = true,
    useSearchToken = false,
    useJWTToken = false,
    isFormData = false,
    useStarGateToken = false
  },
  headers
}) => {
  const { accessToken, searchToken, JWTToken } = getTokens(getState);

  const apiCallHeaders = {
    ...(useAuthToken && accessToken
      ? {
          authorization: `Bearer ${accessToken}`
        }
      : ''),
    ...(useJWTToken && JWTToken
      ? {
          authorization: `Bearer ${JWTToken}`
        }
      : ''),
    ...(useSearchToken && searchToken
      ? {
          searchToken: `Bearer ${searchToken}`
        }
      : ''),
    ...(!isFormData && {
      'Content-Type': 'application/json'
    }),
    ...(headers || {})
  };

  const isServer = typeof window === 'undefined';

  if (url.includes(defaultHost)) {
    const platform =
      !isServer && deviceHelper.isMobile({ isServer })
        ? PLATFORM.MOBILE
        : PLATFORM.OTHER;
    apiCallHeaders['X-Platform'] = platform;
  }

  if (useStarGateToken) {
    const stargateJWTToken = getStargateJwtToken(getState());
    apiCallHeaders['Stargate-Token'] = stargateJWTToken;
  }
  return apiCallHeaders;
};

export default getHeaders;
