import { authenticate, fetchMyInfo } from '@actions/auth';
import { openToggleableElement } from '@stores/toggleables';
import { utils as snackbarUtils } from '@stores/snackbar';
import { LOGIN_DRAWER } from '@constants/toggleables';
import { authTypes } from '@actions-constants/index';
import signOutUser from '@utils/signOutUser';
import { fetchNewToken } from '@api/fetchers/user';
import getTokens from './getTokens';
import captureAPIFailure from './captureAPIFailure';

let isRefreshing = false;
let subscribers = [];

const fetchErrorHandler = async ({
  error,
  action = { type: '' },
  dispatch,
  getState,
  rest = {},
  options,
  headers,
  endpoint,
  source
}) => {
  if (error?.response?.status >= 400 && error?.response?.status < 600)
    captureAPIFailure({ endpoint, error, source, options, headers });

  if (error?.response?.status === 401) {
    const { accessToken, refreshToken } = getTokens(getState);
    const isServer = typeof window === 'undefined';

    if (accessToken && refreshToken) {
      if (!isRefreshing) {
        if (action.type !== 'ME') subscribers.push(action);
        isRefreshing = true;

        try {
          const response = await (await fetchNewToken(refreshToken)).json();
          if (response?.error) throw new Error(response?.error);
          isRefreshing = false;
          dispatch(
            authenticate({
              accessToken: response.access_token,
              refreshToken: response.refresh_token
            })
          );
          await dispatch(fetchMyInfo());
          subscribers.forEach((cb) => dispatch(cb));
        } catch (err) {
          if (!isServer) {
            signOutUser('', { keepSelectedGoal: true });
            openToggleableElement(LOGIN_DRAWER);
          }
          dispatch({ type: `${authTypes.ME}_FAILURE` });
        } finally {
          subscribers = [];
        }
      }
    } else {
      if (!isServer) {
        signOutUser('', { keepSelectedGoal: true });
        openToggleableElement(LOGIN_DRAWER);
      }
      dispatch({ type: `${authTypes.ME}_FAILURE` });
    }
  }

  if (error?.response?.status === 429) {
    snackbarUtils.open({
      message: 'Too many requests. Please try again after sometime.',
      type: 'error'
    });
  }

  if (error?.response?.status >= 500 || error.status >= 500) {
    return dispatch({ ...rest, error, type: `${action.type}_FAILURE` });
  }
  const errorResp = await error?.response?.json();
  return dispatch({
    ...rest,
    error: errorResp,
    type: `${action.type}_FAILURE`,
    statusCode: error?.response?.status
  });
};

export default fetchErrorHandler;
