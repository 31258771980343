import { emptyList, emptyObject } from '@constants/empty';

const getTopologies = (state) => state.topologies;

export const getTopologiesGoalLanguages = (state) =>
  getTopologies(state).goalLanguages ?? emptyList;

export const getTopologyDataByUID = (goalUID) => (state) =>
  getTopologies(state).data[goalUID] || emptyObject;

export const getTopologiesList = (state) => getTopologies(state).data;

const getTopologyInfo = (state, goalUID) => getTopologies(state).data[goalUID];

const getTopologyInfoOrEmptyObject = (state, goalUID) =>
  getTopologyInfo(state, goalUID) || emptyObject;

export const getTopologyColor = (state, goalUID) =>
  getTopologyInfoOrEmptyObject(state, goalUID).colorCode;

export const getOnboardingPreferencesUpdated = (goalUID) => (state) =>
  getTopologies(state).areGoalOnBoardingPreferencesUpdated[goalUID] || false;

export const getTopology = (state, uid) => state.topologies.data[uid];

export const getHasTopologyInfo = (state, uid) => !!getTopology(state, uid);

export const getTopologyOrEmptyObject = (state, uid) =>
  getTopology(state, uid) || emptyObject;

export const getTopologyName = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).name || '';

export const getTopologyCoursesCount = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).coursesCount;

export const getTopologyUpcomingCoursesCountThisMonth = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).upcomingCoursesThisMonth;

export const getIsNoTestGoal = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).isNoTestGoal;

export const getIsCatalogueFeatureAvailable = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).isCatalogueFeatureAvailable || false;

export const getIsPlusAvailable = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).isPlusAvailable || false;

export const getIsPracticeAvailable = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).isPracticeAvailable || false;

export const getIsRecordedGoal = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).isRecordedGoal || false;

export const getTopologySlug = (state, uid) => {
  const topology = getTopologyOrEmptyObject(state, uid);
  return topology.goalSlug || topology.slug;
};

export const getExternalAppsAvailable = (state, uid) =>
  getTopology(state, uid)?.externalApps ||
  getTopology(state, uid)?.externalAppsAvailable;

export const getTopologyPlusCoursesCount = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).plusCourseCount;

export const getIsTopologySelfDataFetched = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).selfDataFetched;

const getTopologyParent = (state, uid) =>
  getTopologyOrEmptyObject(state, uid).parent || emptyObject;

export const getTopologyParentName = (state, uid) =>
  getTopologyParent(state, uid).name || '';

export const getTopologyGoalBenefitsByKey =
  (key) =>
  ({ topologies }) =>
    topologies.goalBenefits[key] ?? {};

export const getGoalInfoByUID = ({ topologies }, goalUID) => {
  return topologies.topologyDetails[goalUID];
};
export const getPreSubscribedStaticData =
  (goalUID) =>
  ({ topologies }) =>
    topologies.preSubscribedStaticContent[goalUID];

export const getGoalInfoByUIDFromData = (state, goalUID) => {
  return state.topologies.data[goalUID] || emptyObject;
};

export const getTopologyOnboardingGoals = ({ topologies }) =>
  topologies.onboardingGoals;

export const getPlatformBenefits =
  (goalUID) =>
  ({ topologies }) =>
    topologies?.platformGoalBenefits[goalUID];

export const getIsSyllabusV1Enabled = (state, goalUID) => {
  return getTopologyOrEmptyObject(state, goalUID).isSyllabusV1Enabled || false;
};

export const getIsPlatformGoalEnabled = (state, goalUID) => {
  return getTopologyOrEmptyObject(state, goalUID).isPlatformGoal || false;
};

export const getGoalSynonyms = ({ topologies }) => topologies?.goalSynonyms;

export const getHideNudge = (goalUID) => (state) =>
  getTopologyOrEmptyObject(state, goalUID).goalLevelOnboarding?.hideNudge ||
  true;
