import { SKIP_REASONS_MAP } from '@constants/practice';
import { generateHours } from '@utils/dates';
import { getImage } from '@utils/imageHelper';

export const noPrefrenceCode = -1;

export const LANGUAGE = {
  ALL: { display_name: 'All', code: 0 }
};

export const DEFAULT_LANGUAGE_INFO = {
  user_preference: 0,
  available_languages: [],
  language_selection_available: false
};

export const PRACTICE_TYPE = {
  FULL: 1,
  EACH_TOPIC: 2,
  PYQ: 3,
  TEXTBOOK: 4,
  INCORRECT: 5
};

export const PRACTICE_FLOW_TYPE = {
  NORMAL_PRACTICE: '1',
  CLASS_PRACTICE: '2',
  SYLLABUS_PRACTICE: '3',
  AUTO_DPP: '4'
};

export const DISLIKE_REASONS = [
  'Mistake in the question/option choices',
  'Mistake in solution',
  'Technical issue',
  'Plagiarism'
];

export const YEAR_FILTER_TYPE = {
  ALL: 'all-filter'
};

export const SKIP_REASONS = [
  {
    why: SKIP_REASONS_MAP.DIFFICULT_QUESTION,
    icon: getImage('practice-session/thinking-face.svg')
  },
  {
    why: SKIP_REASONS_MAP.DIDNT_UNDERSTAND,
    icon: getImage('practice-session/confused.svg')
  },
  {
    why: SKIP_REASONS_MAP.HAVENT_STUDIED,
    icon: getImage('practice-session/exclamation-question.svg')
  }
];

export const REVIEW_TYPE = {
  POSITIVE: 1,
  NEGATIVE: 2
};

export const SUBJECTS = {
  ALL: -1
};

export const MAX_QUESTIONS = 30;
export const MIN_QUESTIONS = 5;

export const FEEDBACK_OPTIONS_MAPPING = {
  POSITIVE: {
    QUALITY_OF_SOLUTIONS: 496,
    QUALITY_OF_QUESTIONS: 497,
    MIX_OF_QUESTIONS: 498,
    INTERFACE: 499,
    SYLLABUS: 500
  },
  NEGATIVE: {
    QUALITY_OF_SOLUTIONS: 501,
    QUALITY_OF_QUESTIONS: 502,
    MIX_OF_QUESTIONS: 503,
    INTERFACE: 504,
    SYLLABUS: 505
  }
};

export const DAILY_GOALS_OPTIONS = [
  { value: 10, label: '10 questions' },
  { value: 20, label: '20 questions' },
  { value: 30, label: '30 questions' }
];

export const getHoursOptions = () => {
  const options = [];
  const { twentyFourHrFormat, twelveHrFormat } = generateHours({
    interval: 30
  });

  twelveHrFormat.forEach((hour, index) => {
    options.push({
      value: twentyFourHrFormat[index],
      label: hour
    });
  });

  return options;
};

export const DAILY_REMINDER_OPTIONS = getHoursOptions();

export const MODAL_TYPES = {
  PRACTICE_REMINDER: 'PRACTICE_REMINDER'
};

export const QUERY_ACTIONS = {
  SHOW_REMINDER_SETTING: 'show_reminder_setting'
};

export const TOOLTIP_MAP = {
  TOTAL_QUESTION_LIMIT_REACHED: 'Total questions limit reached',
  CANNOT_ADD_MORE_QUESTIONS: 'Cannot add more questions',
  NULL: ''
};

export const PRACTICE_MIN_QUESTION_NUM = 5;

export const getRoundedGTPPracticeQuestionCount = (count) => {
  return Math.floor(count / 100) * 100;
};

export const practiceActionTypes = {
  SET_REMINDER_DEFAULT_DATA: 'SET_REMINDER_DEFAULT_DATA'
};

export const MAX_PRACTICE_SESSION_COUNT = 5;
export const TIME_LAPSE_LIMIT = 7;
export const DEFAULT_DAILY_GOAL = 10;
export const DEFAULT_TIME = '09:00';
export const DEFAULT_TIME_IN_TEXT = '09:00 AM';

export const DPP_SET_REMINDER_CARD_VIEWED = 'DPP - Set Reminder Card Viewed ';
export const DPP_SET_REMINDER_CLICKED = 'DPP - Set Reminder Clicked';
export const DPP_PREFERENCES_QUESTION = 'DPP - Preferences Question';
export const PRACTICE_SETTINGS_CLICKED = 'Practice - Settings Clicked';
export const DPP_COMPLETED = 1;
export const DPP_INCOMPLETE = 0;
