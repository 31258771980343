import cookies from '@utils/cookies';
import { getJwtToken, getSearchToken } from '@selectors/auth';

const getTokens = (getState) => {
  let accessToken = cookies.readCookie('accessToken', getState);
  let refreshToken = cookies.readCookie('refreshToken', getState);
  if (typeof window !== 'undefined') {
    if (!accessToken) accessToken = cookies.readCookie('accessToken');
    if (!refreshToken) refreshToken = cookies.readCookie('refreshToken');
  }
  const searchToken =
    typeof getState === 'function' ? getSearchToken(getState()) : '';
  const JWTToken = getJwtToken(getState());
  return { accessToken, refreshToken, searchToken, JWTToken };
};

export default getTokens;
