import camelCase from 'camelcase-keys';
import checkAPIResponseStatus from './checkAPIResponseStatus';

/**
 * Fetch with response status check.
 * This function must be wrapped in a try/catch block to catch exceptions.
 * Being used in **worker** space as well.
 * @param {string} url
 * @param {object} options
 * @returns {*} any
 */
const fetchWithoutErrorHandling = async (url, options) => {
  const {
    headers,
    useCamelCase = false,
    hasTextResponse = false,
    requestType = 'GET',
    isFormData = false,
    body = null
  } = options || {};

  const fetchOptions = {
    method: requestType,
    headers: headers || {},
    credentials: 'same-origin'
  };

  if (typeof window === 'undefined')
    fetchOptions.headers['user-agent'] = 'unacademy-frontend-server';

  if (body) {
    try {
      fetchOptions.body = isFormData ? body : JSON.stringify(body);
    } catch {
      /* */
    }
  }

  const response = await fetch(url, fetchOptions);

  checkAPIResponseStatus(response);
  if (response.status === 204) return response;
  let resp;

  if (!hasTextResponse) {
    try {
      resp = await response.json();
      if (useCamelCase) resp = camelCase(resp, { deep: true });
    } catch {
      /* */
    }
  } else resp = await response.text();
  return resp;
};

export default fetchWithoutErrorHandling;
