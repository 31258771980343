import create from 'zustand/vanilla';
import createHook from 'zustand';

export const goalSelectorTooltips = create((set) => ({
  isFollowGoalTooltipShowing: false,
  isSwitchGoalTooltipShowing: false,
  setShowFollowGoalTooltip: (show = false) =>
    set(() => ({ isFollowGoalTooltipShowing: show })),
  setShowSwitchGoalTooltip: (show = false) =>
    set(() => ({ isSwitchGoalTooltipShowing: show }))
}));

const useGoalSelectorTooltips = createHook(goalSelectorTooltips);

export default useGoalSelectorTooltips;
