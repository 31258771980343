import { schema } from 'normalizr';
import paginateAction from '@utils/paginateAction';
import { HOST_TYPE } from '@utils/api-helper/host';
import { PlusTopologySource } from '@constants/index';
import { topologyTypes } from '../constants';
import {
  topologyListsSchema,
  topologySchema,
  paginatorListSchema
} from '../schemas';

export const fetchOnboardingGoals = () => ({
  type: topologyTypes.FETCH_ONBOARDING_GOALS,
  endpoint:
    'v1/topology/topology-onboarding/?limit=20&scope=paid&include_icons=true&include_platform_goals=true',
  method: 'get',
  schema: topologyListsSchema,
  directPaginatedEntity: true,
  entityType: 'topology'
});

export const getGoalLanguages = (goalUid) => ({
  type: topologyTypes.FETCH_GOAL_LANGUAGES,
  endpoint: `v1/uplus/subscription/subscription_goal_languages/?goal_uid=${goalUid}`,
  method: 'get',
  schema: new schema.Array()
});

export const setGoalLanguage = (goalUid, languageCode, languageText) => ({
  type: topologyTypes.SET_GOAL_LANGUAGE,
  endpoint: 'v1/user/me/plus_language/',
  method: 'post',
  data: {
    language: languageCode,
    goal_uid: goalUid
  },
  goalUid,
  languageText,
  schema: new schema.Object({})
});

export const fetchTopologyChildren = (topologyUid) => ({
  type: topologyTypes.FETCH_TOPOLOGY_CHILDREN,
  endpoint: `v1/uplus/subscription/topology_children/?topology_uid=${topologyUid}`,
  method: 'get',
  topologyUid,
  schema: new schema.Array({ topology: topologySchema }),
  entityType: 'topology'
});

const getTopologyFeed = (key, resetWithoutEmpty, pageSource) => (next) => ({
  type: topologyTypes.FETCH_TOPOLOGY_FEED,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'feedrow',
  processData(data) {
    if (pageSource === PlusTopologySource.TOPIC_FEED) {
      const topologyRow = data.results?.find(
        (feedrow) => feedrow.type === 'topic_group_non_feed' || 'topic_group'
      );
      if (topologyRow) {
        topologyRow.topology = topologyRow.data;
      }
    } else if (pageSource === PlusTopologySource.TOPICS) {
      const topologyRow = data.results?.find(
        (feedrow) => feedrow.type === 'topic_groups'
      );
      if (topologyRow) {
        topologyRow.extra_block_info.uid = key;
      }
    }
    return data;
  }
});

export const fetchTopologyFeed = ({
  topologyUid,
  nextPage = false,
  pageSource,
  resetWithoutEmpty = false,
  params = {}
}) => {
  const queryParams = Object.keys(params).reduce(
    (allParams, key) => `${allParams}&${key}=${params[key]}`,
    ''
  );

  return paginateAction(
    `v1/uplus/syllabus/feed/?topology_uid=${topologyUid}${queryParams}&limit=5`,
    getTopologyFeed(topologyUid, resetWithoutEmpty, pageSource),
    'topologyFeed',
    topologyUid,
    resetWithoutEmpty
  )(nextPage);
};

export const fetchNewSyllabusFeed = (goalUID) => {
  return {
    type: topologyTypes.FETCH_NEW_SYLLABUS_FEED,
    endpoint: `v1/syllabus/home/feed/?goal_uid=${goalUID}`,
    method: 'get',
    goalUID
  };
};

export const fetchTopologyChildrenOfTopic = (topologyUid) => ({
  type: topologyTypes.FETCH_TOPOLOGY_CHILDREN_OF_FREE_COURSE,
  endpoint: `v1/topology/users/${topologyUid}/children/?limit=50`,
  method: 'get',
  topologyUid,
  directPaginatedEntity: true,
  schema: paginatorListSchema,
  entityType: 'topology'
});

export const fetchTopologyInfo = (topologyUid) => ({
  type: topologyTypes.FETCH_TOPOLOGY_INFO,
  endpoint: `v1/topology/users/${topologyUid}/self/`,
  method: 'get',
  topologyUid: topologyUid?.toUpperCase(),
  schema: new schema.Object({ topology: topologySchema }),
  entityType: 'topology',
  processData(data) {
    return {
      ...data,
      selfDataFetched: true
    };
  }
});

const getTopologyCourseFeed = (key, resetWithoutEmpty) => (next) => ({
  type: topologyTypes.FETCH_TOPOLOGY_COURSE_FEED,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'feedrow'
});

export const fetchTopologyCourseFeed = (
  topologyUid,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/uplus/subscription/topology_feed/?topology_uid=${topologyUid}`,
    getTopologyCourseFeed(topologyUid, resetWithoutEmpty),
    'topologyCourseFeed',
    topologyUid,
    resetWithoutEmpty
  )(nextPage);

const getTopologyCourses = (key, resetWithoutEmpty) => (next) => ({
  type: topologyTypes.FETCH_TOPOLOGY_COURSES,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course'
});

export const fetchTopologyCourses = (
  key,
  defaultUrl,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    defaultUrl,
    getTopologyCourses(key, resetWithoutEmpty),
    'topologyCourses',
    key,
    resetWithoutEmpty
  )(nextPage);

export const fetchTopologyExtraInfo = (topologyUid) => ({
  type: topologyTypes.FETCH_TOPOLOGY_EXTRA_INFO,
  endpoint: `v1/uplus/subscription/feed_block_extra_info/?topology_uid=${topologyUid}&block_type=topic_group`,
  method: 'get',
  topologyUid,
  schema: new schema.Object({})
});

const getTopologyFreeCourseFeed = (key, resetWithoutEmpty) => (next) => ({
  type: topologyTypes.FETCH_TOPOLOGY_OF_FREE_COURSE_FEED,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'feedrow',
  processData: (data) => {
    const newData = data;
    newData.results = data.results.map((resultItem) => ({
      ...resultItem,
      label: resultItem.name,
      data: resultItem.top_courses,
      extra_block_info: {
        id: 'course',
        entity_type: 'Course',
        label: resultItem.name,
        name: resultItem.name,
        uid: resultItem.uid
      },
      type: 'course'
    }));
    return newData;
  }
});

export const fetchTopologyFreeCourseFeed = (
  topologyUid,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/topology/users/${topologyUid}/top_courses/`,
    getTopologyFreeCourseFeed(topologyUid, resetWithoutEmpty),
    'topologyFreeCourseFeed',
    topologyUid,
    resetWithoutEmpty
  )(nextPage);

const getFreeCoursesOfAConcept = (key, resetWithoutEmpty) => (next) => ({
  type: topologyTypes.FETCH_FREE_COURSES_OF_A_CONCEPT,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course'
});

export const fetchFreeCoursesOfAConcept = (
  topologyUid,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/topology/users/${topologyUid}/courses/`,
    getFreeCoursesOfAConcept(topologyUid, resetWithoutEmpty),
    'freeCoursesOfAConcept',
    topologyUid,
    resetWithoutEmpty
  )(nextPage);

export const fetchPopularGoalsV2 = () => ({
  type: topologyTypes.FETCH_POPULAR_GOALS_GROUPS,
  endpoint: 'v2/topology/popular_goals_groups/',
  method: 'get'
});

export const searchTopologyV2 = (
  topologyLevel,
  query,
  parentLevel = 30,
  parentUID,
  includeParent = false
) => ({
  type: topologyTypes.FETCH_TOPOLOGIES,
  endpoint: `v1/topology/moderators/search_topology_v2/?level=${topologyLevel}&q=${query}&parent_level=${parentLevel}&parent_uid=${parentUID}`,
  method: 'get',
  schema: new schema.Object({}),
  processData: (data) => {
    const newData = { ...data };
    if (includeParent) {
      const results = data.results.map((item) => {
        return {
          ...item,
          name: `${item.parent.name} ->  ${item.name}`
        };
      });
      newData.results = results;
    }
    return newData;
  }
});

export const fetchPreSubscribedStaticContent = (goalUID) => ({
  type: topologyTypes.FETCH_PRESUBSCRIBED_STATIC_CONTENT,
  endpoint: `api/pre-subscribed-goal-info/${goalUID}`,
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const getPostSubscriptionOnBoardingPreferences = (goalUID) => ({
  type: topologyTypes.FETCH_POST_SUBSCRIPTION_ONBOARDING_PREFERENCES,
  endpoint: 'v1/onboarding/start/',
  method: 'post',
  data: { goal_uid: goalUID }
});

export const savePreferenceAndFetchNext = (data, goalUID) => ({
  type: topologyTypes.SAVE_PREFERENCE_FETCH_NEXT,
  endpoint: 'v1/onboarding/preference/',
  method: 'post',
  data,
  isEditing: !!data.edit_preference,
  goalUID,
  key: goalUID
});

export const fetchAllOnBoardingPreferencesOfAUser = (goalUID) => ({
  type: topologyTypes.FETCH_ALL_ONBOARDING_PREFERENCE_OF_A_USER,
  endpoint: `v1/onboarding/preferences/?goal_uid=${goalUID}`,
  method: 'get'
});

export const updateGoalLevelOnboardingStatus = (goalUID, status) => ({
  type: topologyTypes.UPDATE_GLO_STATUS,
  goalUID,
  status
});

export const fetchSubscriptionEligibleGoals = () => ({
  type: topologyTypes.FETCH_SUBSCRIPTION_GOALS,
  endpoint: 'v1/topology/goal/user_visible/?scope=paid',
  method: 'get'
});

export const fetchGoalBenefits = (key) => ({
  type: topologyTypes.FETCH_GOAL_BENEFITS,
  endpoint: `api/goal-benefits/${key}`,
  method: 'get',
  hostType: HOST_TYPE.CMS,
  key
});
