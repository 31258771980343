import Router from 'next/router';
import getQueryString from '@utils/getQueryString';

const redirect = (
  isServer,
  redirectionUrl,
  ctx,
  query = {},
  permanent = false
) => {
  let redirectTo = redirectionUrl.as;

  // Persisting query params in the URL across redirects.
  const queryString = getQueryString(query);
  redirectTo += queryString;

  if (isServer) {
    ctx.res.writeHead(permanent ? 301 : 302, { Location: redirectTo });
    return ctx.res.end();
  }
  return Router.push({ pathname: redirectionUrl.href, query }, redirectTo);
};

export default redirect;
