import { authTypes, otherTypes } from '@actions-constants/index';
import {
  reauthenticate,
  fetchMyInfo,
  fetchFollowedGoals,
  setSelectedGoal,
  fetchPurchasedItems
} from '@actions/auth';
import { setPreviewModeInRedux } from '@actions/others';
import fetchPageMetaInfo from '@api/fetchers/pageMetaInfo';
import { fetchTopologyInfo } from '@actions/topologies';
import { getMeUid } from '@selectors/auth';
import { getTopologyDataByUID } from '@selectors/topologies';
import { goalSelectorTooltips } from '@stores/goalSelectorTooltips';
import { eraseCookie } from '@utils/cookies';
import redirect from './redirect';

const getRedirectionUrl = (pathname, selectedGoal) => {
  const { goalSlug, slug, uid } = selectedGoal;
  const href = '/goal/[goalSlug]/[goalUID]';
  const as = `/goal/${goalSlug || slug}/${uid}`;

  const routes = {
    '/': selectedGoal && { href, as },
    '/index': selectedGoal && { href, as }
  };
  return routes[pathname];
};

export const checkIsFollowedGoal = (followedGoals = [], goalUID) => {
  const followedGoalIds = followedGoals?.map?.((item) => item.uid);
  const show = !!(goalUID && !followedGoalIds.includes(goalUID));
  goalSelectorTooltips.getState().setShowFollowGoalTooltip(show);
};

export const checkGoalSwitch = (
  followedGoals,
  currentPageGoalUid,
  selectedGoalUid
) => {
  const isCurrentPageGoalFollowed = followedGoals?.find(
    (item) => item.uid === currentPageGoalUid
  );

  if (
    currentPageGoalUid &&
    selectedGoalUid &&
    currentPageGoalUid !== selectedGoalUid &&
    isCurrentPageGoalFollowed
  ) {
    goalSelectorTooltips.getState().setShowSwitchGoalTooltip(true);
  } else {
    goalSelectorTooltips.getState().setShowSwitchGoalTooltip(false);
  }
};

export const getMetaInfoOfPage = async ({ pageData, query }) => {
  if (pageData?.isCMSMeta) {
    const pageMeta = await fetchPageMetaInfo(
      pageData.name,
      query?.goalUID
    ).catch(() => null);
    return pageMeta;
  }
  return null;
};

function redirectToLoginPage({ isServer, asPath, ctx }) {
  const redirectAfterLogin = encodeURIComponent(asPath);
  const loginRedirectURL = `/login?redirectTo=${redirectAfterLogin}`;
  return redirect(
    isServer,
    { href: loginRedirectURL, as: loginRedirectURL },
    ctx
  );
}

export const handlePageInfoAndAuth = async ({
  ctx,
  cookies,
  asPath,
  pathname,
  store,
  pageInfo,
  isStaticPage = false
}) => {
  const isServer = typeof window === 'undefined';
  const isScholarshipPage = pathname === '/scholarship/[eventKey]';
  const isCheckoutPage = pathname === '/redirect/payment';
  const isAppRedirectPlanSelection = pathname === '/redirect/plan';

  store.dispatch(
    reauthenticate({
      accessToken: cookies.accessToken,
      refreshToken: cookies.refreshToken
    })
  );
  let userInfo;
  if (cookies.accessToken) {
    const meDataInStore = getMeUid(store.getState());
    const userDataAPISuccessType = `${authTypes.ME}_SUCCESS`;

    let meResp = { type: userDataAPISuccessType };

    // In case the routing is on a static page and the user data is already fetched, the data doesn't need to be refetched, in case of server-rendered pages, always refetch data.
    const shouldFetchDataAgain = !meDataInStore || !isStaticPage;
    if (shouldFetchDataAgain) {
      const promises = [
        store.dispatch(fetchMyInfo(pathname)),
        store.dispatch(fetchPurchasedItems())
      ];
      [meResp] = await Promise.all(promises);
    }

    if (meResp?.type !== userDataAPISuccessType) {
      store.dispatch({ type: `${authTypes.ME}_FAILURE` }); // Mark the API request as having failed.
      if (pageInfo?.isProtected) {
        // Authorization failed, i.e: Token is either not present or is invalid. Hence, redirect to login page in case page is protected.
        return redirectToLoginPage({ ctx, asPath, isServer });
      }
    }

    if (!isScholarshipPage) {
      const dataLoadingPromises = [];
      if (
        meResp?.type === userDataAPISuccessType &&
        shouldFetchDataAgain &&
        !isCheckoutPage &&
        !isAppRedirectPlanSelection
      )
        dataLoadingPromises.push(store.dispatch(fetchFollowedGoals()));

      await Promise.all(dataLoadingPromises);
    }

    userInfo = meResp.rawResponse || null;
  }
  if (isStaticPage && cookies.selectedGoalUid) {
    const isGoalPage =
      pathname === '/goal/[goalSlug]/[goalUID]' ||
      pathname === '/goal/gtp/[goalSlug]/[goalUID]' ||
      pathname === '/goal/[goalSlug]/OUUJV';
    const selectedGoalAlreadyInReducer = getTopologyDataByUID(
      cookies.selectedGoalUid
    )(store.getState());
    if (!isGoalPage && !selectedGoalAlreadyInReducer?.uid)
      store
        .dispatch(fetchTopologyInfo(cookies.selectedGoalUid))
        .then((response) => {
          if (
            response?.type?.includes('FAILURE') &&
            response?.error &&
            [404, 403, 401].includes(response?.statusCode) // Goal not found or unauthorized
          )
            eraseCookie('selectedGoalUid');
        });
  }
  return { userInfo };
};

const initialize = async ({ pageInfo, ctx, cookies, isMobile }) => {
  const { isServer, store, pathname, query, asPath } = ctx;

  if (isServer) {
    // eslint-disable-next-line no-console
    console.log(asPath, pathname);
  }

  if (asPath === '/health' || asPath === '/calculator') return;

  if (isMobile && !pageInfo?.isMWebSupported) {
    // Right now, isMWebSupported is an opt-in feature.
    redirect(
      isServer,
      { href: '/unsupported-on-mobile', as: '/unsupported-on-mobile' },
      ctx,
      { ...query, path: asPath }
    );

    return { isUserRedirected: true };
  }

  if (query.in_app === 'true') {
    return;
  }

  if (asPath.length > 1 && asPath.endsWith('/')) {
    let route = pathname;
    const asPathWithoutEndingSlash = asPath.replace(/\/*$/gim, '');

    if (pathname === '/[authorID]' && !asPath.includes('@')) {
      route = asPathWithoutEndingSlash;
    }

    redirect(
      isServer,
      { href: route, as: asPathWithoutEndingSlash },
      ctx,
      query
    );

    return { isUserRedirected: true };
  }
  const { goalUID } = query;
  const { selectedGoalUid, __next_preview_data } = cookies;

  // Check if page is protected and the user isn't logged in.
  if (pageInfo?.isProtected && !cookies.accessToken) {
    redirectToLoginPage({ ctx, asPath, isServer });
    return { isUserRedirected: true };
  }

  let inPreviewMode = false;
  if (__next_preview_data) {
    inPreviewMode = true;
    store.dispatch(setPreviewModeInRedux());
  }

  let selectedGoalInfo = null;
  let currentGoalInfo = null;

  if (isServer && selectedGoalUid) {
    selectedGoalInfo = await store.dispatch(fetchTopologyInfo(selectedGoalUid));

    if (
      selectedGoalInfo?.type?.includes('FAILURE') &&
      selectedGoalInfo?.error &&
      [404, 403, 401].includes(selectedGoalInfo?.statusCode) // Goal not found or unauthorized
    ) {
      // Selected Goal has an issue, then in that case remove this cookie.
      ctx.res.setHeader(
        'Set-Cookie',
        `selectedGoalUid=deleted;expires=${new Date().toUTCString()};path=/`
      );
    }
  }

  if (
    isServer &&
    goalUID &&
    goalUID !== selectedGoalUid &&
    !store.getState().topologies.data[goalUID]
  ) {
    currentGoalInfo = await store.dispatch(fetchTopologyInfo(goalUID));
  }

  let selectedGoal = null;
  selectedGoal = selectedGoalUid
    ? store.getState().topologies.data[selectedGoalUid]
    : null;
  if (selectedGoal) {
    store.dispatch(setSelectedGoal(selectedGoal));
  }
  const redirectionUrl = selectedGoal
    ? getRedirectionUrl(pathname, selectedGoal)
    : null;
  const currentGoalUID = query.goalUID || selectedGoal?.uid;

  if (redirectionUrl?.as && !pathname.includes('classroom')) {
    redirect(isServer, redirectionUrl, ctx, query);
    return { isUserRedirected: true };
  }

  const { initialPropsRun } = store.getState().others;
  let userInfo;
  if (isServer || !initialPropsRun) {
    const pageAndAuthInfo = await handlePageInfoAndAuth({
      ctx,
      currentGoalUID,
      asPath,
      pathname,
      cookies,
      isMobile,
      store,
      pageInfo,
      selectedGoal
    });
    store.dispatch({ type: otherTypes.SET_INITIAL_PROPS_RUN });
    userInfo = pageAndAuthInfo.userInfo;
  }

  return {
    userInfo,
    selectedGoalInfo: selectedGoalInfo?.rawResponse,
    currentGoalInfo: currentGoalInfo?.rawResponse,
    inPreviewMode,
    pageMetaInfo: await getMetaInfoOfPage({ pageData: pageInfo, query }),
    selectedGoalUid: cookies.selectedGoalUid || null
  };
};

export default initialize;
