import { s3BucketTypes } from '@constants/index';
import { HOST_TYPE } from '@utils/api-helper/host';
import { otherTypes } from '../constants';
import { paginatorResultsSchema } from '../schemas';

export const fetchFaqs = () => ({
  type: otherTypes.FETCH_FAQS,
  endpoint: 'v1/faq/?type=2',
  method: 'get'
});

export const fetchS3SignInUrl = (
  fileName,
  type = 0,
  uid = '',
  contentType
) => ({
  type: otherTypes.FETCH_S3_SIGNIN_URL,
  endpoint: `v1/s3_url/?objectName=${encodeURIComponent(
    fileName
  )}&type=${type}${uid ? `&uid=${uid}` : ''}${
    contentType ? `&contentType=${contentType}` : ''
  }`,
  method: 'get'
});

export const submitIssue = (data) => ({
  type: otherTypes.SUBMIT_ISSUE,
  endpoint: 'v1/user/me/report_bug/',
  method: 'post',
  data
});

export const fetchLegendsBanner = () => ({
  type: otherTypes.FETCH_LEGENDS_BANNER,
  endpoint: 'v1/feeds/banners/',
  method: 'get'
});

export const fetchIconicSubscriptionInfo = (key) => ({
  type: otherTypes.FETCH_ICONIC_SUBSCRIPTION_INFO,
  endpoint: `subscription-info/${key}`,
  method: 'get',
  iconicKey: key,
  hostType: HOST_TYPE.CMS
});

export const fetchSubscriptionFeatures = (key) => ({
  type: otherTypes.FETCH_SUBSCRIPTION_FEATURES,
  endpoint: `subscription-features/${key}`,
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const fetchSubscriptionHighlights = (key) => ({
  type: otherTypes.FETCH_SUBSCRIPTION_HIGHLIGHTS,
  endpoint: `subscription-highlights/${key}`,
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const fetchPriceHikeInfo = () => ({
  type: otherTypes.FETCH_PRICE_HIKE_INFO,
  endpoint: 'active-price-hikes',
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const fetchSubscriptionFaqs = (key) => ({
  type: otherTypes.FETCH_SUBSCRIPTION_FAQS,
  endpoint: `subscription-faqs/${key}`,
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const fetchOneCarouselFeed = () => ({
  type: otherTypes.FETCH_ONE_CAROUSEL_FEED,
  endpoint: 'v1/feeds/carousel',
  method: 'get',
  entityType: 'feedrow',
  schema: paginatorResultsSchema
});

export const fetchRootPageData = () => ({
  type: otherTypes.FETCH_ROOT_PAGE_DATA,
  endpoint: 'api/root-page',
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const fetchGoalDisplayNames = () => ({
  type: otherTypes.FETCH_GOAL_DISPLAY_NAMES,
  endpoint: 'api/goal-display-names',
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const uploadFile = (
  url,
  file_name,
  doc_type = s3BucketTypes.LEARNER_NOTES
) => ({
  type: otherTypes.UPLOAD_FILE,
  endpoint: 'v1/uploads/document/',
  method: 'post',
  data: {
    url,
    file_name,
    doc_type
  }
});

export const setPreviewModeInRedux = () => ({
  type: otherTypes.SET_PREVIEW_MODE
});

export const fetchProdigyPageData = (eventKey) => ({
  type: otherTypes.FETCH_PRODIGY_DATA,
  endpoint: `api/prodigy-tests/${eventKey}`,
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const verifyUserProdigyDetails = (eventKey) => ({
  type: otherTypes.VERIFY_PRODIGY_DETAILS,
  endpoint: `v1/uplus/prodigy_user/details/?prodigy_key=${eventKey}`,
  method: 'get'
});

export const saveProdigyAdditionalData = (payload) => ({
  type: otherTypes.SAVE_PRODIGY_ADDITIONAL_DATA,
  endpoint: 'v1/uplus/prodigy_user/save/',
  method: 'post',
  data: payload
});

export const setEnrolledCourse = (eventUID) => ({
  type: otherTypes.SET_SAVED_COURSE,
  eventUID
});

export const predictExamRank = (examUID, data) => ({
  type: otherTypes.PREDICT_EXAM_RANK,
  endpoint: `v1/exam/${examUID}/predict_rank/`,
  method: 'post',
  data,
  hostType: HOST_TYPE.TEST_SERIES
});

export const fetchCommunityPageData = () => ({
  types: otherTypes.FETCH_COMMUNITY_STATIC_DATA,
  endpoint: 'api/policies/communityStatic',
  method: 'get',
  hostType: HOST_TYPE.CMS
});

export const setFetchStateOfScheduleDataForDpp = (data) => ({
  type: otherTypes.SET_FETCH_STATE_OF_SCHEDULE_DATA_FOR_DPP,
  data
});
