import useFetch from '@hooks/useFetch';
import { getIsLoggedIn } from '@api/selectors/auth';
import { useMemo } from 'react';
import useMyInfo from '../auth/useMyInfo';

export const PIP_ERROR_CODE = 'E5222';

const usePartPaymentStatus = (goalUID) => {
  const isLoggedIn = useMyInfo(getIsLoggedIn);

  const {
    data,
    isError: error,
    ...rest
  } = useFetch(
    goalUID && isLoggedIn
      ? `v1/subscriptions/pay_in_parts_subscription_details?goal_uid=${goalUID}`
      : null
  );

  const partPaymentStatus = useMemo(() => {
    if (data) return data;
    if (error?.error_code === PIP_ERROR_CODE) return error;
    return null;
  }, [data, error]);

  return { partPaymentStatus, ...rest };
};

export default usePartPaymentStatus;
