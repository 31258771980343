import initialize from '@cont/App/initialize';
import { getStore } from 'src/redux';
import nextCookie from 'next-cookies';
import camelcaseKeys from 'camelcase-keys';
import { getKey as getTopologyKey } from '@api/hooks/topology/useTopology';
import { myInfoSWRKey } from '@api/hooks/auth/useMyInfo';
import { emptyObject } from '@constants/empty';
import deviceHelper from './deviceHelper';

const getUserInfoFallback = (userInfo) => {
  return {
    ...(userInfo && {
      [myInfoSWRKey]: camelcaseKeys(userInfo, { deep: true })
    })
  };
};

const getTopologyFallback = (selectedGoalInfo, currentGoalInfo) => {
  return {
    ...(selectedGoalInfo?.uid && {
      [getTopologyKey(selectedGoalInfo?.uid)]: camelcaseKeys(selectedGoalInfo, {
        deep: true
      })
    }),
    ...(currentGoalInfo?.uid && {
      [getTopologyKey(currentGoalInfo?.uid)]: camelcaseKeys(currentGoalInfo, {
        deep: true
      })
    })
  };
};

/**
 * Usage:
 * Component.getInitialProps = setupInitialProps(Page.pageInfo, (ctx) => {
 *  // Do what you need to for the component here.
 *  // return an object to act as the props for the page.
 * })
 */

const setupInitialProps =
  (callback = async () => ({}), pageInfo = {}) =>
  async (ctx) => {
    const store = getStore();
    const isServer = typeof window === 'undefined';
    ctx.store = store;
    ctx.isServer = isServer;
    const cookies = nextCookie(ctx);
    let componentLevelProps = {};
    const userAgent = isServer
      ? ctx.req.headers['user-agent']
      : window.navigator.userAgent || window.navigator.vendor || window.opera;
    const isMobile = deviceHelper.isMobile({ isServer, userAgent });
    const isIos = deviceHelper.isIos({ isServer, userAgent });
    const {
      selectedGoalInfo,
      currentGoalInfo,
      isUserRedirected,
      userInfo,
      pageMetaInfo,
      inPreviewMode
    } = (await initialize({ pageInfo, ctx, cookies, isMobile })) || {};
    // If user is redirected, stop rendering the component or executing any component-level callbacks.
    if (isUserRedirected) return { initialReduxState: store.getState() };

    // If user is redirected, stop rendering the component or executing any component-level callbacks.
    if (isUserRedirected) return { initialReduxState: store.getState() };

    if (callback && typeof callback === 'function')
      componentLevelProps =
        (await callback({
          ...ctx,
          cookies,
          isServer,
          isIos,
          isMobile,
          userAgent
        })) || {};
    const { accessToken, refreshToken } = ctx.store.getState().auth;
    return {
      initialReduxState: store.getState(),
      userAgent,
      isMobile,
      tokens: { accessToken, refreshToken },
      currentTime: isServer ? new Date().getTime() : null,
      pageMetaInfo,
      inPreviewMode,
      ...componentLevelProps,
      globalFallbackData: {
        ...getUserInfoFallback(userInfo),
        ...getTopologyFallback(selectedGoalInfo, currentGoalInfo),
        ...(componentLevelProps.globalFallbackData || emptyObject)
      }
    };
  };

export default setupInitialProps;
