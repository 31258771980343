export const getSnackBarContent = ({ others }) => others.snackBarContent;

export const getPreviewMode = ({ others }) => others.nextPreviewMode || false;

export const isScheduleDataForDppFetched = ({ others }) => {
  return others?.isScheduleDataForDppFetched;
};

export const getSubscriptionFeatures = ({ others }) =>
  others.subscriptionFeatures;

export const getSubscriptionHighlights = ({ others }) =>
  others.subscriptionHighlights;

export const getIconicFAQs = ({ others }) => others.iconicFAQs;
