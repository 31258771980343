import { plannerEventTypes } from '@constants/schedule';
import { Spacings } from '@styles/index';

export const plannerItemTypes = {
  EVENT: 'event',
  TIMELINE: 'timeline',
  DAY_TITLE: 'day_title',
  GREETING: 'greeting',
  SUBSCRIPTION_FEEDBACK: 'subscription_feedback',
  SUBSCRIPTION_RENEWAL: 'renewal_card',
  FEATURE_CARD: 'feature_card',
  FEATURE_INTRODUCTION: 'feature_introduction',
  FEATURE_AWARENESS: 'feature_card',
  BROWSE: 'browse',
  SUBSCRIPTION_FEEDBACK_V2: 'subscription_feedback_v2',
  FREE_TRIAL_CARD: 'trial_subscription',
  SKELETON_LOADER: 'skeleton_loader'
};
export const daySpacing = 20;
export const timelineTopSpacing = 24;
export const eventTopSpacing = 24;
export const browseTopSpacing = 24;
export const greetingTopSpacing = 20;
export const classCardHeight = 162;

export const dayTitleLeftSpacing = Spacings.SPACING_7B;

export const plannerItemTypeHeightMap = {
  [plannerItemTypes.DAY_TITLE]: 64,
  [plannerItemTypes.TIMELINE]: 12 + timelineTopSpacing,
  [plannerItemTypes.BROWSE]: 160 + browseTopSpacing,
  [plannerItemTypes.GREETING]: 72 + greetingTopSpacing,
  [plannerItemTypes.SUBSCRIPTION_FEEDBACK]: 160 + browseTopSpacing,
  [plannerItemTypes.SUBSCRIPTION_RENEWAL]: 160 + browseTopSpacing,
  [plannerItemTypes.FEATURE_INTRODUCTION]: 168 + browseTopSpacing,
  [plannerItemTypes.FEATURE_AWARENESS]: 160 + browseTopSpacing,
  [plannerItemTypes.SUBSCRIPTION_FEEDBACK_V2]: 160 + browseTopSpacing,
  [plannerItemTypes.SKELETON_LOADER]: 108 + eventTopSpacing,
  [plannerItemTypes.FREE_TRIAL_CARD]: 212 + browseTopSpacing
};

export const eventHeightMap = {
  [plannerEventTypes.CLASS]: classCardHeight + eventTopSpacing,
  [plannerEventTypes.CLASS_WITH_PRACTICE]: 222 + eventTopSpacing,
  [plannerEventTypes.TEST]: 108 + eventTopSpacing,
  [plannerEventTypes.TEST_WITH_EDUCATOR]: 108 + eventTopSpacing,
  [plannerEventTypes.QUIZ]: 108 + eventTopSpacing,
  [plannerEventTypes.COMBAT]: 108 + eventTopSpacing,
  [plannerEventTypes.LIVE_INTERACTION]: 98 + eventTopSpacing,
  [plannerEventTypes.DAILY_PRACTICE_REMINDER]: 84 + eventTopSpacing,
  [plannerEventTypes.OPENHOUSE]: 80 + eventTopSpacing,
  [plannerEventTypes.OFFLINE_TEST]: 136 + eventTopSpacing
};

export const eventPadding = 16;
export const PLUS_SUBSCRIPTION = 'plus_subscription';
export const PLATFORM_SUBSCRIPTION = 'platform_subscription';
export const GTP_SUBSCRIPTION = 'gtp_subscription';
